<template>   
   <HeaderDash />
   
   <ContentUpgrade /> 
</template>

<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/manager/dashboard/HeaderDash.vue'

import ContentUpgrade from '@/components/manager/dashboard/modules/upgrade/ContentUpgrade.vue'
export default 
{
  name: 'Upgrade',
  components: 
  {
    HeaderDash,
 
    ContentUpgrade
  }
}
</script>

<style>


</style>
